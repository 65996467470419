import {
  AfterContentInit,
  ContentChild,
  Directive,
  OnDestroy,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import {
  AbstractControl,
  FormControlStatus,
  FormGroupDirective,
  NgControl,
  NgForm,
  UntypedFormControl,
  ValidationErrors
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';


const FORM_ERROR_MSG_MAP = new Map<
  string,
  string | ((val: any) => string)
>()
  .set('required', $localize`this field is required`)
  .set('email', $localize`enter a valid email`)
  .set('notEqualPasswords', $localize`passwords are not equals`)
  .set('invalidMedication', $localize`please enter a valid medication`)
  .set('invalidPatient', $localize`please enter a valid patient`)
  .set(
    'patientPhoneNumberExists',
    $localize`patient with this phone number already exists in this office`
  )
  .set(
    'patientEmailExists',
    $localize`patient with this email already exists in this office`
  )
  .set('slotRequired', $localize`you have to select one slot time`)
  .set(
    'StaffusernameExists',
    $localize`staff member with this email already exists`
  )
  .set(
    'StaffphoneNumberExists',
    $localize`staff member with this phone number already exists`
  )
  .set('unsuccessiveTeeth', $localize`unsuccessive teeth`)
  .set(
    'invalidWorkingHours',
    $localize`invalid working hours open time should be before or the same as close time`
  )
  .set('passwordNotConfirmed', $localize`passwords are not the same`)
  .set('min', (val: any) => $localize`minimum value is ${val['min']}`)
  .set('max', (val: any) => $localize`maximum value is ${val['max']}`)
  .set(
    'phoneNumberPattern',
    (val: { pattern: number[]; max: number; min: number }) =>
      $localize`invalid phone number`
  )
  .set('INVALID_COUNTRY', $localize`invalid country code`)
  .set('NOT_A_NUMBER', $localize`invalid phone number, not a number`)
  .set('TOO_SHORT', $localize`too short`)
  .set('TOO_LONG', $localize`too long`)
  .set('INVALID_LENGTH', $localize`invalid length`)
  .set(
    'existantMembership',
    $localize`this user is already a member of this account`
  );



class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}




@Directive({
  selector: '[uiFormMatFormFieldErrorDisplayer]',
  standalone: true
})
export class UiMatFormFieldErrorDisplayerDirective
  implements OnDestroy, AfterContentInit
{
  _control!: AbstractControl | null;
  matcher = new MyErrorStateMatcher();

  @ContentChild(MatSelect, { static: false })
  matSelect?: MatSelect;

  @ContentChild(MatInput, { static: false })
  matInput?: MatInput;

  @ContentChild(NgControl, { static: false })
  set control(el: NgControl) {
    this._control = el?.control;
  }

  @ContentChild(MatError, { read: ViewContainerRef })
  errorMsgContainerEl?: ViewContainerRef;
  msgMap = FORM_ERROR_MSG_MAP;
  private subscription!: Subscription | undefined;

  constructor(private renderer: Renderer2) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngAfterContentInit(): void {
    if (this.matInput) {
      this.matInput.errorStateMatcher = this.matcher;
    }
    if (this.matSelect) {
      this.matSelect.errorStateMatcher = this.matcher;
    }

    this.subscription = this._control?.statusChanges.subscribe((x) => {
      this.errroHandler(x);
    });
  }

  errorsTextEls = (errors: ValidationErrors): any[] =>
    Object.entries(errors).map(([key, value]: [string, any]) => {
      const textVal = this.msgMap.get(key);
      return this.renderer.createText(
        textVal ? (typeof textVal === 'string' ? textVal : textVal(value)) : ''
      );
    });

  clearErrors(): void {
    if (this.errorMsgContainerEl) {
      this.errorMsgContainerEl.element.nativeElement.innerText = '';
    }
  }

  private errroHandler(x: FormControlStatus): void {
    {
      this.clearErrors();
      if (this.errorMsgContainerEl) {
        this.errorMsgContainerEl.clear();
        if (x === 'INVALID' && this._control?.errors) {
          this.errorsTextEls(this._control?.errors).forEach((errorEl) =>
            this.renderer.appendChild(
              this.errorMsgContainerEl?.element?.nativeElement,
              errorEl
            )
          );
        }
      }
    }
  }
}
