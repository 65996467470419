/* eslint-disable @typescript-eslint/no-empty-interface */

import {
  Patient,
  PhoneNumberInput,
} from '@doctorus-front-end-monorepo/graphql';
import { EntityConfig } from '@doctorus-front-end-monorepo/util-entity';
export type PaimentKpis = Record<'total_paid' | 'total_due', number>;

const serialize = (obj: Partial<Patient>) => obj;

// export function updatePatientCache<T>(
//   result: T,
//   child: keyof Patient,
//   patientId: string,
//   cache: ApolloCache<any>,
//   del = false
// ): void {
//   const parentId = `Patient:${patientId}`;
//   const updateFn = mutateFn<T & { id: string }>;

//   const parentObj = cache.readFragment<Partial<Patient>>({
//     id: parentId,
//     fragment: gql`
//           fragment ParentFragment on Parent {
//             id
//             ${child} {
//               id
//             }
//           }
//         `
//   });
//   if (parentObj) {
//     console.warn(parentObj)
//     console.warn(parentId)
//     cache.updateFragment<Partial<Patient>>(
//       {
//         id: parentId,
//         fragment: gql`
//             fragment ParentFragment1 on Patient {
//               id
//               ${child} {
//                 id
//               }
//             }
//           `
//       },
//       (data) => {
//         console.warn(data)
//         return (
//           data && Object.hasOwn(data, child)
//             ? {
//                 [child]: updateFn(data[child] ?? [], result as any, 'id')
//               }
//             : {}
//         ) as Partial<Patient>;
//       }
//     );
//   }
// }

export const patientConfig: EntityConfig<any, any> = new EntityConfig({
  baseUri: 'office/patients/',
  feature: $localize`patient profile`,
  serializerFun: serialize,
  // graphQl: {
  //   type: 'Patient',
  //   mutation: {
  //     put: {
  //       name: 'putPatient',
  //       query: gql<
  //         { putPatient: Patient },
  //         {
  //           payload: PatientInput;
  //           id?: any;
  //         }
  //       >`
  //         mutation putPatient($payload: PatientInput!, $id: ID) {
  //           putPatient(payload: $payload, id: $id) {
  //             ...CorePatientFields
  //           }
  //         }
  //       `,
  //     },
  //   },
  // },
  messages: {
    title: {
      new: $localize`:@@patient.message.title.new:New patient`,
      edit: $localize`:@@patient.message.title.edit:Edit patient`,
    },
    action: {
      created: {
        success: $localize`:@@patient.message.created.success:New patient created successfully`,
      },
      updated: {
        success: $localize`:@@patient.message.updated.success:Patient updated successfully`,
      },
      deleted: {
        confirmation: $localize`:@@patient.message.deleted.confirmation:Do you really want to delete this patient ?`,
        success: $localize`:@@patient.message.deleted.success:patient was deleted`,
      },
    },
  },
});

export const phoneNumberConfig: EntityConfig<any, PhoneNumberInput> =
  new EntityConfig({
    baseUri: 'office/phone_numbers/',
    feature: $localize`phone number`,
  });
