import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  CorePatientFieldsFragment,
  PhoneNumberInput,
} from '@doctorus-front-end-monorepo/graphql';
import { phoneNumberValidator } from '@doctorus-front-end-monorepo/util-form';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class PatientFormBuilderService {
  constructor(private fb: FormBuilder) {}
  createForm = (
    entity?: Partial<CorePatientFieldsFragment> | undefined | null
  ) =>
    this.fb.group({
      given_name: [entity?.given_name, Validators.required],
      family_name: [entity?.family_name, Validators.required],
      middle_name: [entity?.middle_name],
      birthdate: [entity?.birthdate],
      gender: [entity?.gender ?? 'male', Validators.required],
      remarks: [entity?.remarks],
      email: [
        entity?.email,
        {
          updateOn: 'blur',
          validators: [Validators.email],
          //asyncValidators: this.forbiddenEmailValidator(entity),
        },
      ],
      // phone_number: [
      //   entity?.phone_number,
      //   {
      //     validators: [phoneNumberValidator()],
      //   },
      // ],
      // alt_phone_numbers: this.fb.array(
      //   entity?.alt_phone_numbers?.map(x => this.createPhoneNumberControl(x)) ??
      //     []
      // ),
      ...(_.isNil(entity) && {
        phone_numbers: this.fb.array([]),
      }),
    });

  createPhoneNumberControl = (value: string): FormControl =>
    new FormControl(value, phoneNumberValidator());

  createPhoneNumberForm = (payload?: PhoneNumberInput) =>
    this.fb.group({
      phone_number: [
        payload?.phone_number,
        [Validators.required, phoneNumberValidator()],
      ],
      remarks: [payload?.remarks],
      availabilities: [payload?.availabilities ?? []],
    });

  // private forbiddenPhoneNumberValidator =
  //   (patient?: Partial<Patient>): AsyncValidatorFn =>
  //   (control: AbstractControl): Observable<ValidationErrors | null> =>
  //     this.asyncValidator.validate(
  //       control,
  //       'office/patients/',
  //       {
  //         ['phone_number']: control.value,
  //         ...(patient?.id && { [`id__ne`]: patient?.id.toString() }),
  //       },
  //       (res: PaginatedPatients) => res.count > 0,
  //       'patientPhoneNumberExists'
  //     );

  // patientShortForm = () =>
  //   this.fb.group({
  //     given_name: ['', Validators.required],
  //     family_name: ['', Validators.required],
  //     middle_name: [''],
  //     birthdate: [],
  //     gender: ['male', Validators.required],
  //     email: [
  //       null,
  //       {
  //         updateOn: 'blur',
  //         validators: [Validators.email],
  //         //asyncValidators: this.forbiddenEmailValidator(entity),
  //       },
  //     ],
  //     phone_number: [
  //       '',
  //       {
  //         validators: [phoneNumberValidator()],
  //       },
  //     ],
  //   });

  // private forbiddenEmailValidator =
  //   (patient?: Partial<Patient>): AsyncValidatorFn =>
  //   (control: AbstractControl): Observable<ValidationErrors | null> =>
  //     this.asyncValidator.validate<Patient>(
  //       control,
  //       'office/patients/',
  //       {
  //         ['email']: control.value,
  //         ...(patient?.id && { [`id__ne`]: patient?.id.toString() }),
  //       },
  //       (res: PaginatedResponseType<Patient>) => res.count > 0,
  //       'patientEmailExists'
  //     );
}
