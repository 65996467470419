import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ShowForMultiLocationsDirective } from '@doctorus-front-end-monorepo/auth';
import {
  CoreAppointmentFieldsFragment,
  CorePatientFieldsFragment,
  DeleteAppointmentGQL,
  PutAppointmentGQL,
  SetAppointmentFeesGQL,
  SetAppointmentMedicalNotesGQL,
  SetAppointmentStatusGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-type';
import { ResolveStatusConfigPipe } from '@doctorus-front-end-monorepo/shared-util';
import {
  EntityMutationService,
  SlideOutPanelService,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import {
  EntityDeleteMutationService,
  EntityDialogService,
} from '@doctorus-front-end-monorepo/ui-entity-dialog';
import { GroupHeaderComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { EntityConfig } from '@doctorus-front-end-monorepo/util-entity';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { QuillEditorComponent, QuillViewComponent } from 'ngx-quill';
import { appointmentConfig } from '../appointment-entity.model';
import { FeesDialogComponent } from '../fees-dialog/fees-dialog.component';
import { WriteAppointmentInputComponent } from '../forms/write-appointment-form/write-appointment-form.component';
import { WriteMedicaNoteFromComponent } from '../forms/write-medica-note-from/write-medica-note-from.component';
import { StatusPickerComponent } from '../status-picker/status-picker.component';
@Component({
  selector: 'appointment-medical-record-appointment-details',
  standalone: true,
  imports: [
    CommonModule,
    StatusPickerComponent,
    MatButtonModule,
    CoalescePipe,
    MatIconModule,
    ResolveStatusConfigPipe,
    GroupHeaderComponent,
    MatFormFieldModule,
    MatDividerModule,
    MatSelectModule,
    ReactiveFormsModule,
    QuillEditorComponent,
    MatInputModule,
    ShowForMultiLocationsDirective,
    QuillViewComponent,
    CoalescePipe,
  ],
  templateUrl: './medical-record-appointment-details.component.html',
  styleUrls: ['./medical-record-appointment-details.component.scss'],
  providers: [
    {
      provide: EntityDeleteMutationService,
      useClass: DeleteAppointmentGQL,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MedicalRecordAppointmentDetailsComponent {
  @Input({ required: true }) patient!: CorePatientFieldsFragment;
  @Input({ required: true }) appointment!: CoreAppointmentFieldsFragment;
  @Output() deselect = new EventEmitter();
  private injector = inject(Injector);
  private eds = inject(SlideOutPanelService);
  private dialogService = inject(EntityDialogService);
  private setStatusGql = inject(SetAppointmentStatusGQL);
  private medicalNoteInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: EntityMutationService,
        useClass: SetAppointmentMedicalNotesGQL,
      },
    ],
  });
  private appointmentFeesInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: EntityMutationService,
        useClass: SetAppointmentFeesGQL,
      },
    ],
  });
  private appointmentDetailsInjector = Injector.create({
    parent: this.injector,
    providers: [
      {
        provide: EntityMutationService,
        useClass: PutAppointmentGQL,
      },
    ],
  });
  editMedicalNote(): void {
    this.eds
      .openEntityWriteForm(
        {
          cmp: WriteMedicaNoteFromComponent,
          entity: this.appointment,
          entityConfig: new EntityConfig({
            feature: $localize`medical note`,
          }),
        },
        this.medicalNoteInjector
      )
      .subscribe();
  }
  setStatus(status: AppointmentStatus | undefined): void {
    if (status) {
      this.setStatusGql
        .mutate({
          payload: {
            id: this.appointment.id,
            status,
          },
        })
        .subscribe();
    }

    //this.eds.openEntityWriteForm({});
  }
  setFees(): void {
    const suggestions = [60, 100, 200, 55, 0];
    if (this.appointment.task_type?.default_fees) {
      suggestions.unshift(this.appointment.task_type?.default_fees);
    }

    this.eds
      .openEntityWriteForm(
        {
          cmp: FeesDialogComponent,
          entity: this.appointment,
          entityConfig: new EntityConfig({
            feature: $localize`appointment fees`,
          }),
          extra: {
            suggestions,
          },
        },
        this.appointmentFeesInjector
      )
      .subscribe();
  }
  editAppointment(): void {
    this.eds
      .openEntityWriteForm(
        {
          cmp: WriteAppointmentInputComponent,
          entity: this.appointment,
          entityConfig: appointmentConfig,
          extra: {
            patient: this.patient,
          },
        },
        this.appointmentDetailsInjector
      )
      .subscribe();
  }
  deleteAppointment(): void {
    this.dialogService
      .openEntityDeleteDialog(
        this.appointment,
        this.injector,
        appointmentConfig
      )
      .subscribe();
  }
  // private _appointment: Appointment;
  // public get appointment(): Appointment {
  //   return this._appointment;
  // }
  // @Input()
  // public set appointment(value: Appointment) {
  //   this._appointment = value;
  //   this.initForm(this.appointment);
  // }
  // @Input() patient!: Patient;
  // sending = signal(false);
  // entityForm: FormGroup;
  // subscription = new Subscription();
  // account = this.accountDs.account;
  // hasMultipleLocations$ = this.accountDs.hasMultipleLocations$;

  // constructor(
  //   private accountDs: AccountDataService,
  //   private ads: AppointmentDialogsService,
  //   private rhns: RouterNavigationHelperService,
  //   private router: Router,
  //   private medicalNotesGql:SetAppointmentMedicalNotesGQL,
  //   private fbs: AppointmentInputBuilderService,
  //   private matDialog: MatDialog,

  //   private cdr: ChangeDetectorRef
  // ) {}

  // get statusConfig(): EnumDisplayConfig {
  //   return this.appointment && STATUS_CONF.appointment[this.appointment.status];
  // }
  // compareFun = (a, b): boolean => a?.id === b?.id;
  // initForm(entity?: Appointment | undefined): void {
  //   this.entityForm = this.fbs.createMedicalForm(entity);
  //   this.subscription.add(
  //     this.entityForm
  //       .get('task_type')
  //       ?.valueChanges.pipe(
  //         startWith(this.entityForm.get('task_type')?.value),
  //         distinctUntilChanged(),
  //         filter((x) => (x && x.note_template ? true : false))
  //       )
  //       .subscribe((x: TaskType) => {
  //         if (
  //           _.isNil(this.entityForm?.get('note').value) ||
  //           this.entityForm
  //             ?.get('note')
  //             ?.value?.replace(' ', '')
  //             ?.replace('<p>', '')
  //             ?.replace('</p>', '')?.length === 0
  //         ) {
  //           this.entityForm.get('note')?.setValue(x.note_template);
  //         } else if (this.appointment?.task_type?.id !== x.id) {
  //           this.matDialog
  //             .open(MedicalNoteTemplatePromptComponent, {
  //               width: '420px',
  //               data: {
  //                 noteTemplate: x?.note_template
  //               }
  //             })
  //             .afterClosed()
  //             .pipe(first())
  //             .subscribe((x) => {
  //               if (x) {
  //                 this.entityForm.get('note')?.setValue(x);
  //               }
  //             });
  //         }
  //       })
  //   );
  // }

  // openDeleteAppointmentDialog(): void {
  //   this.ads
  //     .openDeleteDialog(this.appointment)
  //     .subscribe(() =>
  //       this.router.navigate(this.rhns.medicalRecordRoute(this.patient.id))
  //     );
  // }
  // get appointmentTitle(): string {
  //   return [this.appointment?.task_type?.name, this.appointment?.description]
  //     .filter((x) => (x ? true : false))
  //     .join(' , ');
  // }
  // get fees(): number | undefined {
  //   return this.entityForm.get('fees')?.value;
  // }
  // edit(): void {
  //   // this.ads
  //   //   .openWriteForm({
  //   //     entity: this.appointment,
  //   //     patient: this.patient
  //   //   })
  //   //   .subscribe();
  // }

  // submit(): void {
  //   this.entityForm.markAllAsTouched();
  //   if (this.entityForm.valid) {
  //     this.sending.set(true);
  //     this.medicalNotesGql.mutate(appointmentConfig.serializerFun(this.entityForm.value))
  //       .pipe(
  //         first(),
  //         finalize(() => this.sending.set(false))
  //       )
  //       .subscribe();
  //   }
  // }
}
