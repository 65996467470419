<header class="py-5 flex items-center px-2">
  <mat-icon (click)="close()" class="hover:cursor-pointer">close</mat-icon>
  <input [formControl]="searchCtrl" placeholder="Search ..." i18n-placeholder
    class="text-base font-normal px-2  outline-none text-black w-full" type="text">
</header>
<mat-divider></mat-divider>
@if(loading()) {
<mat-progress-bar mode="query"></mat-progress-bar>

}
@if(hasInput) {
@if(patients$|async;as patients) {
<mat-list class="list">
  <p i18n matSubheader>{{patients.length}}
    patient(s) found</p>
  @for (patient of patients; track $index) {
  <mat-divider></mat-divider>

  <mat-list-item>
    <div class="pt-2" matListItemMeta>
      <mat-icon *hasRoles="[accountRole.DOCTOR]; for: (user$|async)"
        class="text-black material-symbols-outlined hover:cursor-pointer"
        [routerLink]="rhns.medicalRecordRoute(patient.id)">medical_information</mat-icon>
    </div>


    <a class="anchor" [routerLink]="rhns.getPatientRoute(patient.id)" matListItemTitle>{{patient |
      humanName :'full'}}</a>
    <div matListItemLine>{{patient?.reference}}</div>

  </mat-list-item>

  }
</mat-list>

}
@else {
<div class="flex bg-white z-50 flex-col items-center text-gray-800 p-6">
  <mat-icon class="text-5xl" [inline]="true">search</mat-icon>
  <p class="first-letter:uppercase text-center" i18n>no matching patient found</p>
</div>
}
}@else {
<p class="bg-white text-center text-base text-gray-800 p-6">Search patients by their name, email, reference number or
  phone
  number</p>
}
