import { Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Appointment } from '@doctorus-front-end-monorepo/graphql';
import {
  ISlideOutFormComponent,
  SlideOutPanelService,
} from '@doctorus-front-end-monorepo/slide-out-panel';
import { EntityDialogService } from '@doctorus-front-end-monorepo/ui-entity-dialog';
import { appointmentConfig } from '../appointment-entity.model';
import { WriteAppointmentInputComponent } from '../forms/write-appointment-form/write-appointment-form.component';

@Injectable({
  providedIn: 'root',
})
export class AppointmentDialogsService {
  constructor(
    private matDialog: MatDialog,
    private aeds: EntityDialogService<any, any, any>,
    private slidePanelService: SlideOutPanelService
  ) {}

  openWriteForm<R>(
    args: Partial<{
      entity: Appointment;
      patient: any;
      date: Date;
      timezone: string;
    }>,
    injector: Injector
  ) {
    return this.slidePanelService.openEntityWriteForm<
      Appointment,
      ISlideOutFormComponent,
      R
    >(
      {
        cmp: WriteAppointmentInputComponent,
        entity: args.entity,
        entityConfig: appointmentConfig,
        extra: {
          patient: args.patient,
          date: args.date,
          timezone: args.timezone,
        },
      },
      injector
    );
    // .pipe(
    //   first(),
    //   takeWhile((x) => (x ? true : false)),
    //   switchMap((x:AppointmentInput) => this.putPatientAppointmentGql.mutate({
    //     payload : x,
    //     id : args.entity?.id
    //   }).pipe(map(x => x.data?.putPatientAppointment)))
    // );
  }

  openDeleteDialog(entity: Appointment, injector: Injector) {
    return this.aeds.openEntityDeleteDialog(
      entity,
      injector,
      appointmentConfig
    );
  }
}
