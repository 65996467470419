/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { AccountRole } from './types';
@Directive({
  selector: '[hasRoles]',
  standalone: true
})
export class HasRolesDirective implements OnChanges {
  private visible!: boolean;
  private roles!: AccountRole[];
  private user: User | null |undefined

  @Input() set hasRoles(roles: AccountRole[]) {
    this.roles = roles;
  }

  @Input({}) set hasRolesFor(user: User | null |undefined) {
    this.user = user;
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnChanges(): void {
    if (!this.roles?.length || !this.user) {
      return;
    }

    if (this.visible) {
      return;
    }

    // check if account roles include at least one of the input roles
    if (
      this.user['office_roles']
        .split(',')
        .some((role:AccountRole) => this.roles.includes(role))
    ) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.visible = true;

      return;
    }

    this.viewContainer.clear();
    this.visible = false;
  }
}
