import { Dialog } from '@angular/cdk/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { Observable, Subscription, filter, map, shareReplay } from 'rxjs';
import { UpperMenuComponent } from './menu/components/upper-menu/upper-menu.component';

@Component({
  selector: 'doctorus-front-end-monorepo-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [UpperMenuComponent, RouterOutlet, MatSnackBarModule],
})
export class AppComponent implements OnInit {
  title = 'Doctorus';
  subscription = new Subscription();
  authPage$?: Observable<boolean>;
  authUser$ = this.as.user$;
  fullMenu = false;
  routerHelper = this.rhns;
  isSmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isHandsetLandscape$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.HandsetLandscape])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  drawerMode$: Observable<MatDrawerMode> = this.breakpointObserver
    .observe([
      Breakpoints.Small,
      Breakpoints.XSmall,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge,
    ])
    .pipe(
      map(state => {
        if (state.breakpoints[Breakpoints.XLarge]) {
          return 'side';
        } else {
          return 'over';
        }
      })
    );

  @ViewChild('drawer') matSideNav?: MatSidenav;
  constructor(
    // nths: NotificationsHandlerService<any, any, any>,
    private rhns: RouterNavigationHelperService,
    private matDialog: MatDialog,
    public as: AuthService,
    private router: Router,
    private dialog: Dialog,
    private breakpointObserver: BreakpointObserver,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    //this.nths.listen().subscribe();
    this.subscription.add(
      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe((x: NavigationEnd) => {
          this.matDialog.closeAll();
          this.dialog.closeAll();
        })
    );

    this.iconRegistry
      .addSvgIcon(
        'under-construction-page',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/svg/under-construction-page.svg'
        )
      )
      .addSvgIcon(
        'mobile-landscape',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/svg/mobile-landscape-mode.svg'
        )
      )
      .addSvgIcon(
        'perio',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/perio.svg'
        )
      )
      .addSvgIcon(
        'endo',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/endo.svg'
        )
      )
      .addSvgIcon(
        'carie',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/carie.svg'
        )
      )
      .addSvgIcon(
        'medical-note',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/medical-note.svg'
        )
      )
      .addSvgIcon(
        'medical-document',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/medical-document.svg'
        )
      )
      .addSvgIcon(
        'medical-history',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/medical-history.svg'
        )
      )
      .addSvgIcon(
        'medical-prescription',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/medical-prescription.svg'
        )
      )
      .addSvgIcon(
        'medical-image',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/medical-image.svg'
        )
      )
      .addSvgIcon(
        'prosthesis',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/prosthesis.svg'
        )
      )
      .addSvgIcon(
        'surgery',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/surgery.svg'
        )
      )
      .addSvgIcon(
        'new-office',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/new-office.svg'
        )
      )
      .addSvgIcon(
        'medical-office',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/svg/medical-office.svg'
        )
      )
      .addSvgIcon(
        'medical-board',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/medical-board.svg'
        )
      )
      .addSvgIcon(
        'login-illustration',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/svg/login-illustration.svg'
        )
      )
      .addSvgIcon(
        'act',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/act.svg'
        )
      )
      .addSvgIcon(
        'observation',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/observation.svg'
        )
      )
      .addSvgIcon(
        'excel',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/file-types/excel.svg'
        )
      )
      .addSvgIcon(
        'word',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/file-types/word.svg'
        )
      )
      .addSvgIcon(
        'pdf',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/file-types/pdf.svg'
        )
      )
      .addSvgIcon(
        'ppt',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/file-types/ppt.svg'
        )
      )
      .addSvgIcon(
        'text',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/file-types/text.svg'
        )
      )
      .addSvgIcon(
        'file',
        this.sanitizer.bypassSecurityTrustResourceUrl(
          '../../../assets/icons/file-types/file.svg'
        )
      );
  }

  ngOnInit(): void {
    this.authPage$ = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map((event: NavigationEnd) =>
        ['edit-user-profile'].some(chunk => event.url.includes(chunk))
      )
    );
  }
  toggleDrawer(): void {
    this.matSideNav?.toggle();
    this.fullMenu = !this.fullMenu;
  }
}
