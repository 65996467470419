import { CommonModule, CurrencyPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

import {
  AccountRole,
  HasRolesDirective,
} from '@doctorus-front-end-monorepo/auth';
import { CorePatientFieldsFragment } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import { PhoneNumberPipe } from '@doctorus-front-end-monorepo/util-formatting';
import { PaimentKpis } from '../patient.entity.model';
import { PhoneNumberBoxComponent } from '../phone-number-box/phone-number-box.component';

@Component({
  selector: 'patient-patient-box',
  templateUrl: './patient-box.component.html',
  styleUrls: ['./patient-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    CommonModule,
    NgFor,
    MatButtonModule,
    PhoneNumberBoxComponent,
    RouterLink,
    CurrencyPipe,
    ArrayExistPipe,
    PhoneNumberPipe,
    HumanNamePipe,
    HasRolesDirective,
  ],
})
export class PatientBoxComponent {
  math = Math;
  @Input({ required: true }) patient!: CorePatientFieldsFragment & {
    payment_status?: PaimentKpis;
  };
  @Input({ required: true }) mode: 'profile' | 'medical' = 'profile';
  accountRole = AccountRole;
  user$ = this.as.user$;
  constructor(public as: AuthService) {}

  getMeaning = (amount: number) => (amount >= 0 ? '-' : '+');
  age = (birthdate: string | undefined) =>
    birthdate
      ? (
          new Date().getFullYear() - new Date(birthdate).getFullYear()
        ).toString()
      : undefined;
}
