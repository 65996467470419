import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { AuthModule } from '@auth0/auth0-angular';
import { auth0Config } from '@doctorus-front-end-monorepo/auth';
import { GraphQLModule } from '@doctorus-front-end-monorepo/graphql';
import { quillConfig } from '@doctorus-front-end-monorepo/rte';
import { MtxDateFnsDatetimeModule } from '@ng-matero/extensions-date-fns-adapter';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideQuillConfig } from 'ngx-quill';
import { appRoutes } from './app.routes';
import { CustomErrorHandlerService } from './core/services/custom-error-handler/custom-error-handler.service';
import { httpInterceptorProviders } from './http-interceptors';
import { MenuModule } from './menu/menu.module';
import { materialProviders } from './providers';

// const loadInitData = (appInitService: AppInitService) =>
//   appInitService.loadInitials();
export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AuthModule.forRoot(auth0Config),
      GraphQLModule,
      MenuModule,
      MatSnackBarModule,
      MatDateFnsModule,
      MtxDateFnsDatetimeModule,
    ),
    provideQuillConfig(quillConfig ?? {}),
    provideCharts(withDefaultRegisterables()),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withDebugTracing(),
      withComponentInputBinding(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
      }),
    ),
    {
      provide: ErrorHandler,
      useClass:
        process.env['NX_AWS_RUM_DISABLED'] === '0'
          ? CustomErrorHandlerService
          : ErrorHandler,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: loadInitData,
    //   deps: [AppInitService],
    //   multi: true,
    // },
    httpInterceptorProviders,
    materialProviders,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
};
