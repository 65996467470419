import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Appointment } from '@doctorus-front-end-monorepo/graphql';
import { timezoneToOffset } from '@doctorus-front-end-monorepo/util-time';

@Component({
  selector: 'appointment-dates',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dates.component.html',
  styleUrl: './dates.component.scss',
})
export class DatesComponent {
  @Input() appointment!: Pick<Appointment, 'start' | 'end' | 'timezone'>;
  @Input() timeOnly = false;
  @Input() timezone?: string;
  get appointmentOffset() {
    return timezoneToOffset(this.timezone ?? this.appointment.timezone);
  }
}
