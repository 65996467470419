<div class="flex flex-col gap-4">
  @if(appointments.length > 0) {
  <button class="self-end" (click)="writeAppointment()" mat-flat-button>
    <mat-icon>add_circle</mat-icon>
    <span i18n>new appointment</span>
  </button>


  @switch (mode) {
  @case ('patient-board') {
  <table class="patient-board-table" mat-table [dataSource]="appointments">
    <ng-container sticky matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>status</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <appointment-status-picker (statusChange)="updateStatus($event,element)" [status]="element.status">
          @if (element.status|resolveStatusConfig:'appointment';as status) {
          <button [style.color]="status.color" mat-icon-button>
            <mat-icon>{{status.svgIcon}}</mat-icon>
          </button>
          }

        </appointment-status-picker>
      </td>
    </ng-container>
    <ng-container matColumnDef="slot">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n>date</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.start | date:'medium'}} - {{element.end | date:'HH:mm'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="remark">
      <th mat-header-cell *matHeaderCellDef>
        <span i18n="appointment-type">type</span>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element?.task_type?.name | titlecase |coalesce:'-'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="action" stickyEnd>
      <th mat-header-cell i18n="action" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button [matMenuTriggerData]="{ appointment: element }" [matMenuTriggerFor]="actionMenu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <ng-template #noData> - </ng-template>
  </table>
  }
  @default {
  <ul class="text-sm">
    <li [class.border-t]="isFirst" (click)="selected.set(appointment)"
      class="py-4 px-1 hover:cursor-pointer hover:bg-gray-100 border-b border-y-gray-200"
      *ngFor="let appointment of appointments;first as isFirst">
      <div class="flex gap-2 items-center mb-2">
        <mat-icon [style.color]="statusConfig(appointment).color">{{statusConfig(appointment).svgIcon}}</mat-icon>
        <span class=" first-letter:uppercase text-gray-700 font-medium">{{appointment.start
          |date:'y-MM-dd, HH:mm'}} - {{appointment.end |date:'HH:mm'}}</span>

        <span *ngIf="appointment?.fees" class="ml-auto">{{appointment?.fees}}</span>


      </div>
      <div class="ml-8">
        <div class="flex flex-col gap-1">
          <div *ngIf="appointment.task_type" class="flex gap-2 items-center">
            <span class="w-3 h-3 rounded-full" [style.background-color]="appointment.task_type.color"></span>
            <p>{{appointment.task_type.name}}</p>
          </div>
        </div>
        <div class="text-gray-800 italic" *ngIf="appointment.description">
          {{appointment.description}}
        </div>
      </div>
    </li>
  </ul>
  }
  }
  }

  @else {
  <div class="flex  flex-col items-center gap-6 h-full justify-center text-gray-700">
    <p class="first-letter:uppercase font-medium" i18n>no appointments</p>
    <button (click)="writeAppointment()" mat-stroked-button><span i18n>new
        appointment</span></button>
  </div>
  }

</div>
<mat-menu #actionMenu>
  <ng-template matMenuContent let-appointment="appointment">
    <button (click)="writeAppointment(appointment)" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span i18n>edit</span>
    </button>
    <button (click)="deleteAppointment(appointment)" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span i18n>delete</span>
    </button>
  </ng-template>
</mat-menu>