import { InjectionToken } from '@angular/core';
import { Mutation, TypedDocumentNode } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';
import { DocumentNode } from 'graphql';

export const SLIDE_OUT_DATA_TOKEN = new InjectionToken<string>(
  'slide-out-panel-content-component-data'
);
//@Injectable()
export class EntityMutationService extends Mutation {
  override document!: DocumentNode | TypedDocumentNode<{}, EmptyObject>;
  
}
