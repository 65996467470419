import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';

@Component({
  selector: 'doctorus-front-end-monorepo-auth-user-card',
  templateUrl: './auth-user-card.component.html',
  styleUrls: ['./auth-user-card.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatListModule,
    MatDividerModule,
    RouterLink,
    MatIconModule,
  ],
})
export class AuthUserCardComponent {
  @Input() user: User = this.data?.user;
  @Input() mode: 'full' | 'side' = this.data?.mode ?? 'full';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { mode: 'full' | 'side'; user: User },
    private as: AuthService,
  ) { }

  logOut() {
    this.as.logout({
      logoutParams: {
        returnTo: this.document.location.origin,
      },
    });
  }
  get isFullMode(): boolean {
    return this.mode === 'full';
  }
}
