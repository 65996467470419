<main class="container py-10 max-w-screen-lg flex flex-col gap-14">
  <header class="sticky top-0 bg-white z-10 py-4 border-b border-b-gray-100">

    <div class="flex items-center justify-between flex-wrap gap-2">

      <a class="anchor" [routerLink]="isNewPatient() ? null: ['..']">
        <h1 *ngIf="patientInfo">{{patientInfo | humanName:'full':true}}</h1>

      </a>
      <div class="flex gap-2">
        <button mat-flat-button [routerLink]="rhns.getPatientListRoute()">
          <mat-icon>arrow_back</mat-icon>
          <span i18n>to patients list</span>
        </button>
        <button [disabled]="loading()" type="submit" (click)="submit()" color="primary" mat-flat-button>
          <span i18n>{{actionStr}}</span>
        </button>
      </div>

    </div>

  </header>
  <form class="flex flex-col gap-6" [formGroup]="patientForm">
    <section class="fieldset">
      <aside>
        <h2 class="first-letter:uppercase" i18n>identification</h2>
      </aside>
      <main class="grid sm:grid-cols-6 gap-2 flex-wrap">
        <mat-form-field uiFormMatFormFieldErrorDisplayer class="col-span-full">
          <mat-label i18n>given name</mat-label>
          <input matInput formControlName="given_name">
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field class="col-span-full md:col-span-2">
          <mat-label i18n>middle name</mat-label>
          <input matInput formControlName="middle_name">
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field uiFormMatFormFieldErrorDisplayer class="col-span-full md:col-span-4">
          <mat-label i18n>family name</mat-label>
          <input matInput formControlName="family_name">
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field class="col-span-full md:col-span-3" uiFormMatFormFieldErrorDisplayer>
          <mat-label i18n>gender</mat-label>
          <mat-select required formControlName="gender">
            <mat-option value="female"><span class="first-letter:uppercase" i18n>female</span></mat-option>
            <mat-option value="male"><span class="first-letter:uppercase" i18n>male</span></mat-option>
          </mat-select>
          <mat-error></mat-error>
        </mat-form-field>
        <mat-form-field class="col-span-full md:col-span-3" uiFormMatFormFieldErrorDisplayer>
          <mat-label i18n>date of birth</mat-label>
          <input #input matInput type="date" formControlName="birthdate" />
          <mat-icon matIconSuffix class="hover:cursor-pointer" (click)="input.showPicker()">calendar_month</mat-icon>
          <mat-error></mat-error>
        </mat-form-field>
      </main>
    </section>
    <section class="fieldset">
      <aside>
        <h2 class="first-letter:uppercase" i18n>contact</h2>
      </aside>
      <main class="flex flex-col gap-2">
        <mat-form-field>
          <mat-label i18n>email</mat-label>
          <input matInput type="email" formControlName="email" />
          <mat-error></mat-error>
        </mat-form-field>
        <div class="field-label flex items-center justify-between">
          <span class="capitalize" i18n>phone numbers</span>
          <button (click)="newPhoneNumber()" mat-mini-fab><mat-icon>add</mat-icon></button>

        </div>
        @if (isNewPatient()) {
        <section class="flex flex-col gap-4" formArrayName="phone_numbers">
          <form [formGroupName]="i" *ngFor="let control of phoneNumberCtrls;index as i" class="grid grid-cols-6 gap-4">
            <header class="bg-gray-100 flex items-center justify-between p-2 col-span-6">
              <h3># {{control.value.phone_number |phoneNumber}}</h3>
              <mat-icon (click)="removePhoneNumberCtrl(i)" class="hover:cursor-pointer">close</mat-icon>
            </header>
            <mat-form-field class="col-span-3" uiFormMatFormFieldErrorDisplayer>
              <shared-ui-phone-number-picker #picker="phonePicker"
                [initCountry]="oss.account.preferences?.phoneNumberDefaultCountry" formControlName="phone_number">
              </shared-ui-phone-number-picker>
              <mat-hint *ngIf="picker.exampleNumber"><span class="mr-1" i18n>eg
                  :</span>{{picker.exampleNumber}}</mat-hint>

              <mat-error></mat-error>
            </mat-form-field>
            <mat-form-field class="col-span-3">
              <mat-label i18n>availabilities</mat-label>
              <mat-select multiple formControlName="availabilities">
                <mat-option [value]="'WHATSAPP'">WhatsApp</mat-option>
                <mat-option [value]="'VIBER'">Viber</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-span-6">
              <mat-label i18n>remarks</mat-label>
              <textarea matInput formControlName="remarks"></textarea>
            </mat-form-field>
          </form>
        </section>
        }@else {
        <ul>
          @for (phoneNumber of patient()?.phone_numbers; track $index) {
          <li class="flex gap-1 hover:bg-gray-100 p-2">
            <div>
              <div class="flex gap-2">
                <p>{{phoneNumber.phone_number | phoneNumber }}</p>
                @for (availability of phoneNumber.availabilities; track $index) {
                <span class="tag text-xs">{{availability}}</span>
                }

              </div>
              @if (phoneNumber.remarks) {
              <i>{{phoneNumber.remarks}}</i>

              }
            </div>
            <button (click)="deletePhoneNumber(phoneNumber)" class="ml-auto" mat-icon-button
              color="warn"><mat-icon>delete</mat-icon></button>
            <button (click)="writePhoneNumber(phoneNumber)" mat-icon-button><mat-icon>edit</mat-icon></button>

          </li>

          }
        </ul>
        }
      </main>
    </section>
    <section class="fieldset">
      <aside>
        <h2 class="first-letter:uppercase" i18n>remarks</h2>
      </aside>
      <main class="flex flex-col gap-2">
        <mat-form-field>
          <textarea placeholder="type your remarks" i18n-placeholder matInput type="text" formControlName="remarks">
          </textarea>
        </mat-form-field>
      </main>
    </section>
  </form>
  <footer *ngIf="patient">
    <section class="rounded-md p-6 bg-red-100 flex justify-between items-center">
      <div class="text-red-700">
        <h3 class="first-letter:uppercase" i18n>delete patient</h3>
        <p i18n>The patient will be removed and it will no longer have access to your applications.</p>
      </div>
      <button (click)="deletePatient()" color="warn" mat-flat-button>
        <span>delete</span>
      </button>
    </section>
  </footer>
</main>