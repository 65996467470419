import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CdkPortalOutletAttachedRef, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentRef,
  Inject,
  Optional,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { IEntity } from '@doctorus-front-end-monorepo/util-entity';
import { isNil } from 'lodash';
import {
  debounceTime,
  finalize,
  first,
  map,
  startWith,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import { BaseSlideOutPanelComponent } from '../base-slide-out-panel';
import { EntityMutationService } from '../slide-out-panel-tokens';
import {
  ISlideOutFormComponent,
  SlideOutFormPanelData,
} from '../slide-out-panel-types';
@Component({
  selector: 'shared-ui-slide-out-form-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    PortalModule,
    MatButtonModule,
    MatProgressBar,
  ],
  templateUrl: './slide-out-form-panel.component.html',
  styleUrl: './slide-out-form-panel.component.scss',
})
export class SlideOutFormPanelComponent<
  E extends IEntity,
  C extends ISlideOutFormComponent,
  R = E
> extends BaseSlideOutPanelComponent<C, R> {
  private componentRef!: ComponentRef<C>;
  headerTitle = this.data.entityConfig
    ? this.data.entityConfig.putTitle(isNil(this.data.entity))
    : this.data.title ?? 'N/A';
  error: any = {};
  loading = signal(false);
  constructor(
    @Inject(DIALOG_DATA) public override data: SlideOutFormPanelData<E, C>,
    @Optional() private mutationService: EntityMutationService
  ) {
    super(data);
    console.log(this.mutationService.document);
  }
  setAttachedForm(cmpRef: CdkPortalOutletAttachedRef): void {
    this.componentRef = cmpRef as ComponentRef<C>;
  }
  submit(): void {
    this.componentRef.instance.checkForm();
    this.componentRef.instance.form.statusChanges
      .pipe(
        tap(x => this.loading.set(true)),
        startWith(this.componentRef.instance.form.status),
        debounceTime(300),
        first(x => x !== 'PENDING'),
        takeWhile(x => x !== 'INVALID'),
        tap(() => console.warn(this.componentRef.instance.form)),
        switchMap(() => {
          const serializerFun = this.data.entityConfig?.serializerFun;
          const payload = serializerFun
            ? serializerFun(this.componentRef.instance.form.value)
            : this.componentRef.instance.form.value;
          //this.dialogRef.close(payload);
          return this.mutationService
            .mutate({
              payload: payload,
              id: this.data.entity?.id,
            })
            .pipe(
              tap(x => (this.error = x.errors)),
              map(x => x.data)
            );
        }),
        first(),
        finalize(() => this.loading.set(false))
      )
      .subscribe({
        next: x => this.dialogRef.close(x),
        error: error => {
          console.warn(error);
        },
      });
  }
}
