import { AuthConfig } from '@auth0/auth0-angular';

export const auth0Config: AuthConfig = {
  domain: process.env['NX_AUTH0_DOMAIN'],
  clientId: process.env['NX_AUTH0_CLIENT_ID'],

  authorizationParams: {
    redirect_uri: `${window.location.origin}/staff-board`,
    audience: process.env['NX_AUTH0_AUDIENCE'],
    ui_locales: 'fr-FR en',
    scope: 'openid profile email offline_access',
  },

  useRefreshTokens: true,
  httpInterceptor: {
    allowedList: [
      {
        uri: 'http://localhost/api/*',
      },
      {
        uri: process.env['NX_REST_API_URL'] + '*',
      },
      // {
      //   uri: process.env['NX_GRAPHQL_API_URL'] + '*',
      // },
    ],
  },
};
