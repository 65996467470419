import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { LocaleInterceptor } from './locale.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  //...(environment.environment === 'dev-local' ? [{ provide: HTTP_INTERCEPTORS, useClass: IdentityHeadersLocalInterceptor, multi: true }] : []),
  { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
];
