// @Component({
//   selector: 'patient-patient-details-base',
//   standalone: true,
//   imports: [CommonModule],
//   templateUrl: './patient-details-base.component.html',
//   styleUrl: './patient-details-base.component.scss',

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationsHandlerService } from '@doctorus-front-end-monorepo/graphql';
import { Subscription, map, merge, shareReplay } from 'rxjs';

// })
export class PatientDetailsBaseComponent {
  route = inject(ActivatedRoute);
  breakpointObserver = inject(BreakpointObserver);
  appointmentMessageMapping: { [k: string]: string } = {
    '=0': 'No appointments',
    '=1': 'One appointment',
    other: '# Appointments',
  };
  paimentMessageMapping: { [k: string]: string } = {
    '=0': 'No Paiments',
    '=1': 'One Paiment',
    other: '# Paiments',
  };
  math = Math;
  isXSmall$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(
    map(result => result.matches),
    shareReplay()
  );
  nhs = inject(NotificationsHandlerService);
  subscription = new Subscription();
  constructor() {
    this.subscription.add(
      merge(
        this.nhs.listenAppointment(),
        this.nhs.listenToPatientAppointments(),
        this.nhs.listentPatientPaymentDelete(),
        this.nhs.listentPatientPaymentPut()
      ).subscribe()
    );
  }
}
