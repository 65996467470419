import '@angular/localize/init';
import { bootstrapApplication } from '@angular/platform-browser';
import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
setDefaultOptions({
  locale: fr,
});

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));

console.log('>>> NX_AUTH0_DOMAIN', process.env['NX_AUTH0_DOMAIN']);
