// /* eslint-disable @typescript-eslint/no-empty-interface */
// import { EntityConfig, IEntity } from '@doctorus-front-end-monorepo/util-entity';
// import { AccountLocation } from '../../../models/entities/account/location.entity';
// import { TaskType } from '../../../graphql/generated';
// import { ApiUser } from '../../../models/entities/user.entity';

import {
  Account,
  AccountLocation,
  DocumentModel,
  PrintConfig,
  TaskType,
} from '@doctorus-front-end-monorepo/graphql';

export enum AccountRole {
  MEDICAL_ASSISTANT = 'ASSISTANT',
  DOCTOR = 'DOCTOR',
  MANAGER = 'MANAGER',
  OWNER = 'OWNER',
}
export enum SubscriptionStatus {
  PENDING = 0,
  ACCEPTED = 1,
  REFUSED = 2,
}
export type AccountConfig = Account & {
  defaultLocation: AccountLocation | undefined;
  defaultTaskType: TaskType | undefined;
  defaultPrintConfig: PrintConfig | undefined;
  defaultDocumentModel: DocumentModel | undefined;
  hasMultipleLocations: boolean;
};
