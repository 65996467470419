<main>
  <h4 class="mb-2">{{appointment.start | date:'shortTime'}} -> {{appointment.end | date:'shortTime'}}</h4>

  <header class="flex items-center text-sm gap-2">
    {{appointmentTitle}}
  </header>
  <main>
    <div class="note-box overflow-y-auto">
      <quill-view class="max-h-full text-sm " *ngIf="appointment.note else empty" [content]="appointment.note"
        format="html" theme="snow"></quill-view>

      <ng-template #empty>
        <small class="ml-4 mt-4" i18n>no medical notes</small>
      </ng-template>
    </div>
  </main>
</main>
