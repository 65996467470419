import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'staff-board',
        loadChildren: () =>
          import('./staff-board/staff-board-routing.module').then(
            m => m.staffBoardRoutes
          ),
      },
      {
        path: ':officeId',
        loadChildren: () =>
          import('./office-manager/office-manager-routing.module').then(
            m => m.routes
          ),
      },
      {
        path: '',
        redirectTo: 'staff-board',
        pathMatch: 'full',
      },
    ],
  },
];
