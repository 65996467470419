<section *ngIf="mode === 'profile' else medical" class="entity-key-value-container">
  <!-- <img class="rounded-full" width="72" height="72" [ngSrc]="patientImageUrl" /> -->
  <div class="flex justify-between">
    <div class="py-2">
      <h2 class="first-letter:uppercase">{{ patient | humanName :'full' }}</h2>

      <h3>{{ patient.reference }}</h3>
      @if (patient.email) {
      <a [href]="'mailto:'+patient.email">{{ patient.email }}</a>

      }

    </div>
    <div class="flex gap-2 ml-auto">
      <button *hasRoles="[accountRole.DOCTOR]; for: (user$|async)"
        [routerLink]="['../../../','medical-record',patient.id]" mat-icon-button>
        <mat-icon>medical_information</mat-icon>
      </button>
      <button [routerLink]="['edit']" mode="write" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div class="flex flex-col gap-2">

    @if (patient.phone_numbers|arrayExist) {
    <div class="text-gray-700 items-center flex gap-4 flex-wrap text-sm">
      @for (phonenumber of patient.phone_numbers; track $index) {
      <patient-phone-number-box [phonenumber]="phonenumber" mode="LARGE"></patient-phone-number-box>

      }
    </div>
    }
    @if (patient.remarks) {
    <i class="text-gray-700">{{patient.remarks}}</i>
    }
  </div>



</section>
<ng-template #medical>
  <section
    class=" entity-key-value-container flex justify-between items-start flex-wrap gap-2 py-4 pl-4 pr-2 bg-white w-full">
    <div>
      <a [routerLink]="['../..','office','patient',patient.id]" class="anchor font-medium">{{ patient | humanName
        :'full'
        }}</a>
      <p class="text-gray-600 flex gap-1">
        <span *ngIf="age(patient.birthdate) as age" i18n>{{age}} years old</span>
        <span *ngIf="age(patient.birthdate) && patient.reference">-</span>
        <span *ngIf="patient?.reference">{{patient.reference}}</span>
      </p>
      <p *ngIf="patient.remarks" class="text-gray-600">{{patient.remarks}}</p>
    </div>
    <ng-container *ngIf="patient.payment_status as paymentKpis">
      <p *ngIf="paymentKpis.total_due && paymentKpis.total_due !== 0" class="text-sm font-medium text-gray-600"
        [class.text-red-700]="paymentKpis.total_due && paymentKpis.total_due > 0">
        <span>{{getMeaning(paymentKpis.total_due)}} {{math.abs(paymentKpis.total_due)| currency : 'TND' :
          'symbol'
          :
          '0.0-0'}} </span>
      </p>
    </ng-container>

  </section>
</ng-template>