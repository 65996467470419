import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuillEditorComponent } from 'ngx-quill';
import 'quill-mention';
import { AccountDataService } from '../../../../auth/src';
import { PutPreferenceGQL } from '../../../../data-access/graphql/src';
import { SuggestionItem, quillConfig } from '../config';
@Component({
  selector: 'rte-rte',
  standalone: true,
  imports: [CommonModule, QuillEditorComponent, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RteComponent),
      multi: true,
    },
  ],
  templateUrl: './rte.component.html',
  styleUrl: './rte.component.scss',
})
export class RteComponent implements ControlValueAccessor {
  @Input() placeholder =
    $localize`type # to select or create a medical tag and add it to your content`;
  textCtrl = new FormControl();
  isDisabled = false;
  modules = {
    ...quillConfig.modules,
    mention: {
      onSelect: (item: SuggestionItem, insertItem: any) => {
        if (item.id?.includes('new#')) {
          const val: string = (item.id.replace('new#', '') as string).trim();
          if (val) {
            this.createTag(val);
            insertItem({
              ...item,
              id: val,
              value: val,
            });
          }
        } else {
          insertItem(item);
        }
      },
      mentionContainerClass: 'ql-mention-list-container',
      allowedChars: /^[A-Za-z0-9_\sÅÄÖåäöéàçè]*$/,
      mentionDenotationChars: ['#'],
      positioningStrategy: 'fixed',
      source: (searchTerm: string, renderList: any, mentionChar: any) => {
        let values: SuggestionItem[] = [];
        if (['#'].includes(mentionChar)) {
          values = this.tags;
        }
        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = values.filter(x =>
            x.value?.includes(searchTerm.trim()),
          );
          if (matches.length > 0) {
            renderList(matches, searchTerm);
          } else {
            renderList(
              [
                {
                  id: 'new#' + searchTerm,
                  value: '+ Create ' + '`' + searchTerm + '`',
                  new: true,
                },
              ],
              searchTerm,
            );
          }
        }
      },
    },
  };

  private putPreference = inject(PutPreferenceGQL);
  private acd = inject(AccountDataService);
  private matSnackBar = inject(MatSnackBar);
  tags =
    this.acd.account.preferences?.medicalTags?.map(x => ({
      id: x,
      value: x,
    })) ?? [];
  onChange = (a: string | null) => {};
  onTouched = () => {};

  writeValue(obj: string): void {
    this.textCtrl.setValue(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  createTag(tag: string): void {
    this.putPreference
      .mutate({
        payload: {
          medicalTags: this.tags.map(x => x.value).concat([tag]),
        },
      })
      .subscribe(x => {
        this.matSnackBar.open($localize`Medical tag created`);
        this.tags =
          x.data?.putPreference?.preferences?.medicalTags?.map(x => ({
            id: x,
            value: x,
          })) ?? [];
      });
  }
}
