import { HttpErrorResponse } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloLink } from '@apollo/client/core';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { AuthService } from '@auth0/auth0-angular';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import {
  AppsyncNetworkError,
  ServerErrorSnackBarComponent,
  isJSON,
} from '@doctorus-front-end-monorepo/logger';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { filter, first, firstValueFrom, map } from 'rxjs';
import { patientTypePolicy } from './type-policy/patient';

loadErrorMessages();
loadDevMessages();
@NgModule({
  imports: [ApolloModule],
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, snackbar: MatSnackBar, as: AuthService) {
        const http = httpLink.create({
          uri: process.env['NX_GRAPHQL_API_URL'],
        });
        const authOptions: AuthOptions = {
          type: 'OPENID_CONNECT',
          jwtToken: () =>
            firstValueFrom(
              as.getAccessTokenSilently().pipe(
                filter(x => (x ? true : false)),
                first(),
                map(x => 'Bearer ' + x)
              )
            ),
        };
        const region = 'eu-west-1';
        const error = onError((arg: ErrorResponse) => {
          if (arg.graphQLErrors) {
            arg.graphQLErrors.forEach(element => {
              snackbar?.openFromComponent(ServerErrorSnackBarComponent, {
                data: isJSON(element.message)
                  ? JSON.parse(element.message)
                  : { detail: element.message },
              });
            });
          }
          if (arg.networkError) {
            console.warn(arg);

            const error = arg.networkError as HttpErrorResponse;
            if (error.status && error.status === 401) {
              console.error(error);
              as.logout();
            } else {
              (
                arg.networkError as unknown as AppsyncNetworkError
              ).error.errors.forEach(element => {
                snackbar?.openFromComponent(ServerErrorSnackBarComponent, {
                  data: isJSON(element.message)
                    ? JSON.parse(element.message)
                    : { detail: element.message },
                });
              });
            }
          }
          console.error(arg);
        });
        const link = ApolloLink.from([
          createAuthLink({
            url: process.env['NX_GRAPHQL_API_URL'] ?? '',
            region,

            auth: authOptions,
          }),
          createSubscriptionHandshakeLink(
            {
              url: process.env['NX_GRAPHQL_API_URL'] ?? '',
              region,
              auth: authOptions,
            },
            error.concat(http)
          ),
        ]);
        return {
          cache: new InMemoryCache({
            typePolicies: {
              // Type policy map
              Patient: patientTypePolicy,
            },
          }),
          fetchOptions: {
            mode: 'no-cors',
          },
          link,
        };
      },
      deps: [HttpLink, MatSnackBar, AuthService],
    },
  ],
})
export class GraphQLModule {}
