/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  Appointment,
  AppointmentInput,
  Patient,
} from '@doctorus-front-end-monorepo/graphql';
import { STATUS_CONF } from '@doctorus-front-end-monorepo/shared-type';
import { humanize } from '@doctorus-front-end-monorepo/shared-util';
import { EntityConfig } from '@doctorus-front-end-monorepo/util-entity';
import * as dateFns from 'date-fns';

export type AppointmentDialogData = {
  appointment?: Appointment;
  patient?: Patient;
};

const toString = (entity: Appointment): string =>
  $localize`${
    entity.patient_info ? humanize(entity.patient_info, 'medium', true) : ''
  } at ${dateFns.format(new Date(entity.start), 'yyyy-LL-dd HH:mm')} ( ${
    STATUS_CONF.appointment[entity.status].label
  } )`;

const serialize = (args: AppointmentInput): Partial<AppointmentInput> => args;
export const appointmentConfig: EntityConfig<Appointment, AppointmentInput> =
  new EntityConfig<Appointment, AppointmentInput, Patient>({
    baseUri: 'office/appointments/',
    feature: $localize`appointment`,
    toString: toString,
    serializerFun: serialize,
    realTimeDataChangeConfig: {
      soundNotify: false,
      visualNotify: true,
      visualDuration: 5000,
    },
    messages: {
      title: {
        new: $localize`:@@appointment.message.title.new:New appointment`,
        edit: $localize`:@@appointment.message.title.edit:Edit appointment`,
      },
      action: {
        created: {
          success: $localize`:@@appointment.message.created.success:appointment was successfully created`,
        },
        updated: {
          success: $localize`:@@appointment.message.updated.success:appointment updated`,
        },
        deleted: {
          confirmation: $localize`:@@appointment.message.deleted.confirmation:Do you really want to delete this appointment ?`,
          success: $localize`:@@appointment.message.deleted.success:appointment was deleted`,
        },
      },
    },
  });
