<ui-layout-group-header>
  <span title i18n class="first-letter:uppercase">appointment details</span>
  <ng-container>
    <button (click)="deleteAppointment()" color="warn" mat-icon-button><mat-icon>delete</mat-icon></button>

    <button (click)="deselect.emit()" mat-icon-button><mat-icon>close</mat-icon></button>
  </ng-container>
</ui-layout-group-header>
<main class="grid  gap-3 mt-2 grid-cols-5 grid-rows-2 items-start">
  <section (click)="editAppointment()" class="flex flex-wrap gap-1 bordered clickable col-span-2">
    <article class="tile col-span-2">
      <div class="title" i18n>schedules</div>
      <div class="value">{{appointment.start |date:'longDate'}}, {{appointment.start |date:'shortTime'}} ->
        {{appointment.end
        |date:'shortTime'}}</div>
    </article>
    <article class="tile">
      <div class="title" i18n="appointment-type">type</div>
      <div class="value">{{appointment.task_type?.name | coalesce:"-"}}</div>
    </article>
    <article class="tile">
      <div class="title" i18n>location</div>
      <div class="value">{{appointment.location?.name | coalesce:"-"}}</div>
    </article>
  </section>
  <appointment-status-picker (statusChange)="setStatus($event)" [status]="appointment.status">
    <article *ngIf="appointment.status|resolveStatusConfig:'appointment' as status"
      class="tile bordered clickable  col-start-1">
      <div class="title" i18n>status</div>
      <div class="value flex gap-1 items-center">
        <mat-icon [style.color]="status.color">{{status.svgIcon}}</mat-icon>
        <span class="first-letter:uppercase">{{status.label}}</span>
      </div>
    </article>
  </appointment-status-picker>

  <article (click)="setFees()" class="tile bordered clickable  col-start-2 ">
    <div class="title" i18n>fees</div>
    <div class="value">{{appointment.fees |coalesce:"-" }}</div>
  </article>


  <main (click)="editMedicalNote()"
    class="p-2 bordered clickable col-start-3 row-start-1 self-auto row-span-2 col-span-3">
    <article class="tile">
      <div class="title" i18n>subject</div>
      <div class="value">{{appointment.description | coalesce:"-"}}</div>
    </article>
    <article class="tile">
      <div class="title" i18n>content</div>
      <div class="value">
        <quill-view [content]="appointment.note | coalesce:'-'" format="html" theme="snow"></quill-view>
      </div>
    </article>
  </main>
</main>