import { KeyValuePipe, NgFor, TitleCasePipe } from '@angular/common';
import { Component, model } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  AppointmentStatus,
  EnumDisplayConfig,
  STATUS_CONF,
} from '@doctorus-front-end-monorepo/shared-type';
import { ResolveStatusConfigPipe } from '@doctorus-front-end-monorepo/shared-util';

@Component({
  selector: 'appointment-status-picker',
  templateUrl: './status-picker.component.html',
  styleUrls: ['./status-picker.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StatusPickerComponent,
    },
  ],
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgFor,
    TitleCasePipe,
    KeyValuePipe,
    ResolveStatusConfigPipe,
  ],
})
export class StatusPickerComponent {
  status = model<AppointmentStatus>(AppointmentStatus.PENDING);
  // private _status: AppointmentStatus;
  // public get status(): AppointmentStatus {
  //   return this._status;
  // }
  // @Input({ required: false })
  // public set status(value: AppointmentStatus) {
  //   this._status = value;
  // }
  // @Output() changed = new EventEmitter<AppointmentStatus>();

  // onChange = (status: AppointmentStatus) => {};

  // onTouched = () => {};

  // touched = false;

  // disabled = false;
  // writeValue(obj: AppointmentStatus): void {
  //   this.status = obj;
  // }
  // registerOnChange(fn: any): void {
  //   this.onChange = fn;
  // }
  // registerOnTouched(fn: any): void {
  //   this.onTouched = fn;
  // }
  // setDisabledState?(isDisabled: boolean): void {
  //   this.disabled = isDisabled;
  // }
  // markAsTouched() {
  //   if (!this.touched) {
  //     this.onTouched();
  //     this.touched = true;
  //   }
  // }

  // statusConfig = (status: AppointmentStatus): EnumDisplayConfig =>
  //   STATUS_CONF.appointment[status];

  statues = Object.entries(STATUS_CONF.appointment) as unknown as Array<
    [AppointmentStatus, EnumDisplayConfig]
  >;

  updateStatus(status: AppointmentStatus): void {
    if (status !== this.status()) {
      this.status.set(status);
    }
  }

  // select(status: any): void {
  //   if(status !== this.status) {
  //     this.markAsTouched();
  //     this.status = status;
  //     this.onChange(status);
  //     this.changed.emit(status);
  //   }

  // }
}
