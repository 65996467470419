<mat-toolbar *ngIf="isLarge$|async" color="primary"
  class="bg-white text-black flex justify-between items-center gap-4 border-b border-b-gray-300">
  <img class="h-10 sm:h-6" [src]="'assets/img/logo.png'">
  <!-- <doctorus-front-end-monorepo-fast-search (toggled)="toggleSearch()" [id]="searchBarId"
    class="search-bar  mat-elevation-z3 rounded-md" *ngIf="searchActive()"></doctorus-front-end-monorepo-fast-search> -->
  <div class=" gap-4 text-sm  text-black flex items-center">
    <ng-container *ngIf="(selectedOffice$ | async) as office">

      <button data-rum-id="patients-fast-search" (click)="toggleSearch($event)" mat-icon-button>
        <mat-icon>person_search</mat-icon>
      </button>
      <button data-rum-id="today-appointments" (click)="openTodayAppointments()" mat-icon-button>
        <mat-icon>today</mat-icon>
      </button>
      <ng-container *ngTemplateOutlet="accountMenu">
      </ng-container>
    </ng-container>
    <ng-container *ngTemplateOutlet="authUserAvatar">
    </ng-container>
  </div>
</mat-toolbar>
<mat-toolbar *ngIf="isSmall$|async" color="primary"
  class="bg-white text-black flex justify-between items-center gap-4 border-b border-b-gray-300">
  <img *ngIf="!searchActive()" class="h-6" [src]="'assets/img/logo.png'">
  <!-- <doctorus-front-end-monorepo-fast-search (toggled)="toggleSearch()" [id]="searchBarId"
    class="search-bar  mat-elevation-z3 rounded-md" *ngIf="searchActive()"></doctorus-front-end-monorepo-fast-search> -->
  <div class=" gap-4 text-sm  text-black flex items-center">
    <ng-container *ngIf="(selectedOffice$ | async) as office">

      <button data-rum-id="patients-fast-search" *ngIf="!searchActive()" (click)="toggleSearch($event)" mat-icon-button>
        <mat-icon>person_search</mat-icon>
      </button>
      <ng-container *ngTemplateOutlet="accountMenu">
      </ng-container>

    </ng-container>
    <ng-container *ngTemplateOutlet="authUserAvatar">
    </ng-container>
  </div>
</mat-toolbar>
<ng-container *ngIf="isXSmall$|async">
  <ng-template #topBar>
    <mat-toolbar color="primary"
      class="bg-white text-black flex justify-between items-center gap-4 border-b border-b-gray-300">
      <div class="flex">
        <button mat-icon-button><mat-icon>menu</mat-icon></button>
        <img class="h-10" [src]="'assets/img/log-emblem.png'">
      </div>

      <div class=" gap-4 text-sm  text-black flex items-center">
        <ng-container *ngIf="(selectedOffice$ | async) as office">

          <button data-rum-id="patients-fast-search" (click)="toggleSearch($event)" mat-icon-button>
            <mat-icon>person_search</mat-icon>
          </button>
          <button data-rum-id="today-appointments" (click)="openTodayAppointments()" mat-icon-button>
            <mat-icon>today</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngTemplateOutlet="authUserAvatar">
        </ng-container>
      </div>
    </mat-toolbar>
  </ng-template>

</ng-container>


<ng-template #authUserAvatar>
  <img matRipple (click)="openAuthStaffCard(user)" *ngIf="(authUser$|async) as user"
    class="hover:cursor-pointer w-10 rounded-full" [src]="user.picture">
</ng-template>
<ng-template #accountMenu>
  <ng-container *ngIf="(selectedOffice$ | async) as office">
    <button class="text-black" mat-button [matMenuTriggerFor]="menu">
      <mat-icon>expand_more</mat-icon>

      <span class="text-sm">{{ office.alias | titlecase}} <span class="text-sm text-gray-700">({{today
          |date:'zzz':currentOffset}})</span></span>
    </button>
    <mat-menu #menu="matMenu">
      <p class="p-4 gap-4 text-gray-700 flex items-center">
        {{ office.alias | titlecase}}
      </p>
      <mat-divider></mat-divider>

      <button [routerLink]="[office.id,'office-settings','general']" mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span class="text-sm" i18n>settings</span>
      </button>
      <button [routerLink]="[office.id,'office-settings','memberships']" mat-menu-item>
        <mat-icon>group_add</mat-icon>
        <span class="text-sm" i18n>invite a member</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item>
        <mat-icon>domain_add</mat-icon>
        <span class="text-sm" i18n>create office</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-template>
