import { TypePolicy } from '@apollo/client/cache/inmemory/policies';

import { gql } from 'apollo-angular';
import {
  Appointment,
  MedicalNotesFieldsFragmentDoc,
  Patient,
  PatientPayment,
} from '../generated';

const fetchPatientChildren = <T extends { patient: string }>(
  cache: any,
  id: string,
  child: string
): T[] =>
  Object.entries(cache.extract())
    .filter(
      (entry: [string, T]) =>
        entry[0].startsWith(child + ':') &&
        Object.hasOwn(entry[1], 'patient') &&
        entry[1]['patient'] === id
    )
    .map(x => x[1]) as T[];

export const patientTypePolicy: TypePolicy = {
  fields: {
    medicalTags: {
      // Field policy for the isInCart field
      read(
        obj,
        { variables, field, args, cache, toReference, readField, storage }
      ) {
        const patient = cache.readFragment<
          Pick<
            Patient,
            | 'upload_documents'
            | 'medical_notes'
            | 'appointments'
            | 'generated_documents'
          >
        >({
          id: `Patient:${variables['id']}`,
          fragmentName: 'PatientMedicalNotesFrag',
          fragment: gql`
            ${MedicalNotesFieldsFragmentDoc}
            fragment PatientMedicalNotesFrag on Patient {
              ...MedicalNotesFields
            }
          `,
        });
        return Array.from(
          new DOMParser()
            .parseFromString(
              [
                ...(patient?.appointments?.map(x => x.note) ?? []),
                ...(patient?.generated_documents?.map(x => x.content) ?? []),
                ...(patient?.upload_documents?.map(x => x.description) ?? []),
                ...[patient?.medical_notes ?? ''],
              ].join('</br>'),
              'text/html'
            )
            .getElementsByClassName('mention')
        )
          .map(x => x.getAttribute('data-value'))
          .filter(x => (x ? true : false))
          .filter((value, index, array) => array.indexOf(value) === index);
      },
    },
    // appointments: {
    //   read(obj, { variables, field, args, cache, toReference, readField }) {
    //     return fetchPatientChildren(
    //       cache,
    //       variables?.id?.toString(),
    //       'Appointment',
    //     );
    //   },
    // },
    // payments: {
    //   read(obj, { variables, field, args, cache, toReference, readField }) {
    //     return fetchPatientChildren(
    //       cache,
    //       variables?.id?.toString(),
    //       'PatientPayment',
    //     );
    //   },
    // },
    // Field policy map for the Product type
    payment_status: {
      read(obj, { variables, field, args, cache, toReference, readField }) {
        const patient = cache.readFragment<{
          appointments: Array<Pick<Appointment, 'fees' | 'status'>>;
          payments: Array<Pick<PatientPayment, 'amount'>>;
        }>({
          id: `Patient:${variables['id']}`,
          fragment: gql`
            fragment PatientFrag on Patient {
              id
              appointments {
                status
                fees
              }
              payments {
                amount
              }
            }
          `,
        });
        return patient?.appointments && patient?.payments
          ? {
              total_due:
                patient.appointments.reduce(
                  (acc: number, current: Appointment) =>
                    acc + (current?.fees ?? 0),
                  0
                ) -
                patient.payments.reduce(
                  (acc: number, current) => acc + current.amount,
                  0
                ),
              total_paid: patient.payments.reduce(
                (acc: number, current) => acc + current.amount,
                0
              ),
            }
          : undefined;
      },
    },
    prescriptions: {
      read(obj, { variables, field, args, cache, toReference, readField }) {
        return fetchPatientChildren(
          cache,
          variables?.['id']?.toString(),
          'Prescription'
        );
      },
      // merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
      //   console.log(existing)
      //   console.log(incoming)
      //   const merged: any[] = existing ? existing.slice(0) : [];
      //   const prescriptionNameToIndex: Record<string, number> = Object.create(null);
      //   if (existing) {
      //     existing.forEach((prescription, index) => {
      //       prescriptionNameToIndex[readField<string>("patient", prescription)] = index;
      //     });
      //   }
      //   incoming.forEach(prescription => {
      //     const patient = readField<string>("patient", prescription);
      //     if(patient) {
      //       const index = prescriptionNameToIndex[patient];
      //       if (typeof index === "number") {
      //         // Merge the new author data with the existing author data.
      //         merged[index] = mergeObjects(merged[index], prescription);
      //       } else {
      //         // First time we've seen this author in this array.
      //         prescriptionNameToIndex[patient] = merged.length;
      //         merged.push(prescription);
      //       }
      //     }

      //   });
      //   return merged;
      // },
    },
  },
};
