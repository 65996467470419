import { Pipe, PipeTransform } from '@angular/core';
import { E164Number, NumberFormat, parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'phoneNumber',
  standalone: true,
})
export class PhoneNumberPipe implements PipeTransform {
  transform(
    value: E164Number | undefined,
    mode: NumberFormat = 'INTERNATIONAL'
  ): string {
    if (value) {
      try {
        const phoneNumber = parsePhoneNumber(value);
        if (phoneNumber.isValid()) return parsePhoneNumber(value).format(mode);
        return value;
      } catch {
        return value;
      }
    }
    return '-';
  }
}
