<table matSort class="mt-6" (matSortChange)="updateSort($event)" mat-table [dataSource]="patients()">
  <ng-container sticky matColumnDef="name">
    <th sticky mat-header-cell mat-sort-header="family_name" *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n="full-name">name</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <a class="text-blue-700 font-medium flex items-center py-2" [routerLink]="['../', element.id]">
        {{ element | humanName:'full' | titlecase }}</a>
    </td>
  </ng-container>
  <ng-container sticky matColumnDef="gender">
    <th sticky mat-header-cell *matHeaderCellDef>
      <span class="first-letter:uppercase" i18n>gender</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element | humanName:'genderOnly'| titlecase }}
    </td>
  </ng-container>
  <ng-container sticky matColumnDef="reference">
    <th sticky mat-header-cell mat-sort-header="reference" *matHeaderCellDef>
      <span i18n>reference</span>
    </th>
    <td mat-cell *matCellDef="let element">{{element?.reference}}</td>
  </ng-container>
  <ng-container matColumnDef="phone_number">
    <th sticky mat-header-cell *matHeaderCellDef>
      <span i18n>phone number</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="flex gap-1">
        @if(element.phone_numbers|arrayExist) {
        @for (phone_number of element.phone_numbers; track $index;let isLast = $last) {
        <patient-phone-number-box [phonenumber]="phone_number" mode="SMALL"></patient-phone-number-box>

        @if (!isLast) {
        <span>|</span>
        }

        }
        }@else {
        -
        }
      </div>

    </td>
  </ng-container>
  <ng-container matColumnDef="added">
    <th sticky mat-header-cell *matHeaderCellDef mat-sort-header="added">
      <span i18n>added</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{element.added|date:'shortDate'|coalesce:'-'}}
    </td>
  </ng-container>
  <ng-container matColumnDef="total_due">
    <th sticky mat-header-cell *matHeaderCellDef>
      <span i18n>due</span>
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element?.total_due | currency : 'TND' : 'symbol' : '1.0-1' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="action" stickyEnd>
    <th mat-header-cell i18n="action" *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button [matMenuTriggerData]="{ patient: element }" [matMenuTriggerFor]="actionMenu" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr queryParamsHandling="merge" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <ng-template #noData> - </ng-template>
</table>
<mat-paginator class="ml-auto sticky bottom-0 z-20" [pageIndex]="pageIndex()" showFirstLastButtons
  [length]="patientsCount()" [pageSize]="pageSize()" (page)="updatePage($event)" [pageSizeOptions]="[50, 100, 150]">
</mat-paginator>

<mat-menu #actionMenu>
  <ng-template matMenuContent let-patient="patient">
    <button *hasRoles="[accountRole.DOCTOR]; for: user" [routerLink]="rhns.medicalRecordRoute(patient.id)"
      mat-menu-item>
      <mat-icon>medical_information</mat-icon>
      <span i18n>medical records</span>
    </button>
    <button [routerLink]="['..',patient.id,'edit']" [state]="patient" [queryParams]="{
        redirectLink : ['../../list']
    }" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span i18n>edit</span>
    </button>
  </ng-template>
</mat-menu>