import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  TitleCasePipe,
} from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import {
  AccountDataService,
  ShowForMultiLocationsDirective,
} from '@doctorus-front-end-monorepo/auth';
import { CoreAppointmentFieldsFragment } from '@doctorus-front-end-monorepo/graphql';
import {
  EnumDisplayConfig,
  STATUS_CONF,
} from '@doctorus-front-end-monorepo/shared-type';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { QuillViewComponent } from 'ngx-quill';
import { StatusPickerComponent } from '../status-picker/status-picker.component';

@Component({
  selector: 'appointment-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    CommonModule,
    StatusPickerComponent,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    ShowForMultiLocationsDirective,
    QuillViewComponent,
    NgSwitchDefault,
    TitleCasePipe,
    CurrencyPipe,
    DatePipe,
    CoalescePipe,
  ],
})
export class AppointmentDetailsComponent {
  @Input() mode: 'medical-note' | 'medical-note-small' = 'medical-note';

  @Input() appointment!: CoreAppointmentFieldsFragment;
  get statusConfig(): EnumDisplayConfig {
    return this.appointment && STATUS_CONF.appointment[this.appointment.status];
  }

  hasMultipleLocations$ = this.accountDs.hasMultipleLocations$;

  constructor(private accountDs: AccountDataService) {}

  get appointmentTitle(): string {
    return [this.appointment?.task_type?.name, this.appointment?.description]
      .filter(x => (x ? true : false))
      .join(' , ');
  }
}
