import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
  input,
  model,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { User } from '@auth0/auth0-angular';
import {
  AccountRole,
  HasRolesDirective,
} from '@doctorus-front-end-monorepo/auth';
import { CorePatientFieldsFragment } from '@doctorus-front-end-monorepo/graphql';
import { HumanNamePipe } from '@doctorus-front-end-monorepo/shared-util';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import {
  CoalescePipe,
  PhoneNumberPipe,
} from '@doctorus-front-end-monorepo/util-formatting';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { PhoneNumberBoxComponent } from '../phone-number-box/phone-number-box.component';
@Component({
  selector: 'patient-patients-list',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    HumanNamePipe,
    HasRolesDirective,
    ArrayExistPipe,
    PhoneNumberBoxComponent,
    MatButtonModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    PhoneNumberPipe,
    CoalescePipe,
  ],
  templateUrl: './patients-list.component.html',
  styleUrl: './patients-list.component.scss',
})
export class PatientsListComponent {
  patients = input.required<CorePatientFieldsFragment[]>();
  patientsCount = input.required<number>();
  pageSize = model.required<number>();
  pageIndex = model.required<number | null>();
  @Output() orderingChange = new EventEmitter<string>();
  @Input() user?: User | null;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns = [
    'name',
    'reference',
    'gender',
    'phone_number',
    'added',
    'total_due',
    'action',
  ];
  accountRole = AccountRole;
  rhns = inject(RouterNavigationHelperService);
  updatePage(event: PageEvent): void {
    this.pageSize.set(event.pageSize);
    this.pageIndex.set(event.pageIndex);
  }
  updateSort(event: Sort): void {
    const direction = event.direction === 'asc' ? '' : '-';
    const value = event.direction ? `${direction}${event.active}` : '';
    this.orderingChange.emit(value);
  }
}
