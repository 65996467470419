import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AsyncPipe,
  CommonModule,
  DatePipe,
  I18nPluralPipe,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  TitleCasePipe,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  model,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import {
  Appointment,
  CoreAppointmentFieldsFragment,
  CorePatientFieldsFragment,
  DeleteAppointmentGQL,
  PutPatientAppointmentGQL,
  SetAppointmentStatusGQL,
} from '@doctorus-front-end-monorepo/graphql';
import { AppointmentStatus } from '@doctorus-front-end-monorepo/shared-type';
import {
  HumanNamePipe,
  ResolveStatusConfigPipe,
} from '@doctorus-front-end-monorepo/shared-util';
import { Observable, map } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AccountRole,
  HasRolesDirective,
  ShowForMultiLocationsDirective,
} from '@doctorus-front-end-monorepo/auth';
import { EntityMutationService } from '@doctorus-front-end-monorepo/slide-out-panel';
import { EntityDeleteMutationService } from '@doctorus-front-end-monorepo/ui-entity-dialog';
import { GroupContainerComponent } from '@doctorus-front-end-monorepo/ui-layout';
import { ArrayExistPipe } from '@doctorus-front-end-monorepo/util-array';
import { CoalescePipe } from '@doctorus-front-end-monorepo/util-formatting';
import { RouterNavigationHelperService } from '@doctorus-front-end-monorepo/util-navigation';
import { compareDesc } from 'date-fns';
import { AppointmentBase } from '../appointment-base';
import { appointmentConfig } from '../appointment-entity.model';
import { AppointmentTagComponent } from '../appointment-tag/appointment-tag.component';
import { AppointmentDialogsService } from '../services/appointment-dialogs.service';
import { StatusPickerComponent } from '../status-picker/status-picker.component';
@Component({
  selector: 'appointment-appointments-list',
  templateUrl: './appointments-list.component.html',
  styleUrls: ['./appointments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: EntityMutationService,
      useExisting: PutPatientAppointmentGQL,
    },
    {
      provide: EntityDeleteMutationService,
      useExisting: DeleteAppointmentGQL,
    },
  ],
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgFor,
    NgIf,
    GroupContainerComponent,
    ResolveStatusConfigPipe,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    StatusPickerComponent,
    MatMenuModule,
    MatListModule,
    RouterLink,
    ShowForMultiLocationsDirective,
    MatDividerModule,
    AsyncPipe,
    TitleCasePipe,
    DatePipe,
    I18nPluralPipe,
    CoalescePipe,
    ArrayExistPipe,
    CommonModule,
    AppointmentTagComponent,
    HumanNamePipe,
    HasRolesDirective,
  ],
})
export class AppointmentsListComponent extends AppointmentBase {
  @Input({
    required: true,
    transform: (value: CoreAppointmentFieldsFragment[]) =>
      [...value]?.sort((a, b) =>
        compareDesc(new Date(a.start), new Date(b.start))
      ),
  })
  appointments: CoreAppointmentFieldsFragment[] = [];
  @Input({ required: true }) patient!: CorePatientFieldsFragment;
  @Input({ required: true }) mode!: 'patient-board' | 'medical-record';
  selected = model<CoreAppointmentFieldsFragment | undefined>(undefined);

  pluralMapping: { [k: string]: string } = {
    '=0': 'no appointments',
    '=1': 'one appointment',
    other: '# appointments',
  };
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  displayedColumns = ['status', 'slot', 'remark', 'action'];
  override entityConfig = appointmentConfig;
  accountRole = AccountRole;

  @Output() delete = new EventEmitter<Appointment>();
  user$ = this.as.user$;
  constructor(
    private injector: Injector,
    private matSnackBar: MatSnackBar,
    public as: AuthService,
    private ads: AppointmentDialogsService,
    private setAppointmentStatusGql: SetAppointmentStatusGQL,
    //private appds: AppointmentStateService,
    public rnhs: RouterNavigationHelperService,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }
  writeAppointment(appointment?: Appointment): void {
    this.ads
      .openWriteForm(
        {
          ...(appointment && { entity: appointment }),
          patient: this.patient,
        },
        this.injector
      )
      .subscribe();
  }
  deleteAppointment(appointment: Appointment): void {
    this.ads.openDeleteDialog(appointment, this.injector).subscribe();
  }

  updateStatus(status: AppointmentStatus, appointment: Appointment): void {
    this.setAppointmentStatusGql
      .mutate({
        payload: {
          status,
          id: appointment.id,
        },
      })
      .subscribe(() =>
        this.matSnackBar.open($localize`Appointment status updated`)
      );
    //this.appds.updateStatus(status, appointment).subscribe();
  }
}
