import { Injectable } from '@angular/core';
import { Account, GetAccountGQL } from '@doctorus-front-end-monorepo/graphql';
import { filter, map } from 'rxjs';
import { AccountConfig } from './types';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService {
  private _account!: AccountConfig;
  public get account(): AccountConfig {
    return this._account;
  }
  public set account(value: Account) {
    this._account = this.createAccountConfig(value);
    localStorage.setItem('account-id', value.id);
  }
  public accountId: number | string;
  public getAccountQueryRef = this.getAccountGQL.watch(
    {},
    {
      fetchPolicy: 'cache-only',
      notifyOnNetworkStatusChange: true,
      refetchWritePolicy: 'merge',
    }
  );
  public account$ = this.getAccountQueryRef.valueChanges.pipe(
    filter(x => (x?.data?.getAccount ? true : false)),
    map(x => x?.data?.getAccount)
  );

  public hasMultipleLocations$ = this.account$.pipe(
    map(x => x.locations && x.locations.length > 1)
  );
  set selectedAccount(accountId: number | string) {
    this.accountId = accountId;
  }
  constructor(private getAccountGQL: GetAccountGQL) {
    this.getAccountQueryRef.valueChanges
      .pipe(filter(x => (x.data?.getAccount ? true : false)))
      .subscribe(x => (this.account = x.data.getAccount));
  }
  private findDefault = <T extends { default?: boolean }>(
    objs: T[]
  ): T | undefined => objs && (objs.find(x => x.default) ?? objs[0]);
  private createAccountConfig = (account: Account) => ({
    ...account,
    defaultLocation: this.findDefault(account.locations ?? []),
    defaultTaskType: this.findDefault(account.taskTypes),
    defaultPrintConfig: this.findDefault(
      account.preferences?.printConfigs ?? []
    ),
    defaultDocumentModel: this.findDefault(
      account.preferences?.documentModels ?? []
    ),
    hasMultipleLocations: account.locations && account.locations.length > 1,
  });
}
