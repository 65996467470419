<header class="flex items-center gap-2 p-2 border-b border-b-gray-200">
  <button (click)="close()" mat-icon-button> <mat-icon>close</mat-icon>
  </button>
  <h2 class="first-letter:uppercase">{{headerTitle}}</h2>
  <button [disabled]="loading()" type="button" (click)="submit()" class="ml-auto" color="primary" mat-flat-button>
    <span class="capitalize" i18n>submit</span>
  </button>


</header>
@if (loading()) { <mat-progress-bar mode="query"></mat-progress-bar>
}
<main class="p-8">
  <ng-template (attached)="setAttachedForm($event)" [cdkPortalOutlet]="componentPortal"></ng-template>

</main>